<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              v-for="breadcrumbItem in breadcrumbItems"
              :key="breadcrumbItem.name"
              :to="breadcrumbItem.to"
              :active="!!breadcrumbItem.active"
            >
              {{ breadcrumbItem.name }}
            </b-breadcrumb-item>
            <b-breadcrumb-item active>{{ title }}</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-overlay :show="showLoading">
      <b-card>
        <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
          <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
            {{ title }}
          </h4>
        </div>
        <div class="row mb-2" v-if="title == '部門客戶'">
          <div class="col-5">
            <b-form-select
              :value="selectedBranch"
              :options="myBranchesOptions"
              :disabled="changingBranch"
              @change="handleChangeSelectedBranch"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- 選擇部門 --</b-form-select-option
                >
              </template>
            </b-form-select>
          </div>
          <div class="col-5">
            <b-form-select
              v-model="selectedStaff"
              :options="staffOptions"
              :disabled="changingStaff"
              @change="handleChangeSelectedStaff"
            >
              <template #first>
                <b-form-select-option :value="null"
                  >-- 全部帳號 --</b-form-select-option
                >
              </template>
            </b-form-select>
          </div>
          <div class="col-2">
            <b-button @click="searchCustomer" variant="primary">
              查詢
            </b-button>
          </div>
        </div>
        <b-card-body class="p-0">
          <div class="row">
            <div class="col-12 col-xl-8 mt-3">
              <span>{{ staffNameLabel }}: {{ staff.name }}</span
              ><br />
              <span>{{ staffIdLabel }}: {{ staff.employee_code }}</span
              ><br />
            </div>
          </div>
          <hr />

          <!-- 所屬客戶資料 -->
          <div class="row mb-1">
            <div class="col-8">
              <h5>客戶清單</h5>
            </div>
            <div class="col-2">
              <StaffAddCustomer
                ref="staffAddCustomer"
                :staff="staff"
                @bind="bindCustomer"
              ></StaffAddCustomer>
            </div>
          </div>

          <customer-staff-operator
            :filter="filter"
            :operator="operator"
            :boundTypes="boundTypes"
            @trigger-filter="fetchCustomers()"
            @trigger-change-staff="showChangeStaff()"
            @trigger-unbind="showUnbindStaff()"
            @trigger-change-bound-type="showChangeBoundType()"
            class="mb-3"
            :textMapping="textMapping"
          />
          <div class="row mt-5">
            <div class="col-12">
              <b-overlay rounded="sm">
                <b-table
                  striped
                  hover
                  responsive
                  :items="customers"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :per-page="perPage"
                  :current-page="currentPage"
                >
                  <template #head(id)="">
                    <b-form-checkbox
                      @change="handleSelectAll"
                      v-model="isSelectAll"
                    >
                    </b-form-checkbox>
                  </template>

                  <template #cell(id)="data">
                    <b-form-checkbox
                      :value="data.item.pivot.id"
                      v-model="selectedPivotIds"
                      :disabled="!filter.doesBound"
                      @change="handleChecboxChange"
                    />
                  </template>
                  <template #cell(avatar_url)="data">
                    <div
                      class="d-flex align-items-center justify-content-center"
                      style="cursor: pointer"
                    >
                      <a @click="goToCustomer(data.item)">
                        <b-avatar
                          :src="data.item.avatar_url"
                          variant="secondary"
                          size="2rem"
                        ></b-avatar>
                      </a>
                    </div>
                  </template>
                  <template #cell(name)="data">
                    <div class="d-flex" style="cursor: pointer">
                      <a
                        @click="goToCustomer(data.item)"
                        class="s-text-primary"
                      >
                        {{ hiddenString(data.item.name, 20) }}
                      </a>
                    </div>
                  </template>
                  <template #cell(bound_type)="data">
                    {{ mapBoundTypeName(data.item.bound_type) }}
                  </template>
                  <!-- <template #cell(actions)="data"> -->
                  <!--   <b-button -->
                  <!--     title="前往專屬聊天室" -->
                  <!--     v-b-tooltip.hover -->
                  <!--     class="icon-btn ml-2" -->
                  <!--     target="_blank" -->
                  <!--     :to="{ -->
                  <!--       name: 'LiffChatroomGoToChatStaff', -->
                  <!--       params: { orgCode: organization.code }, -->
                  <!--       query: { source_type: 'customer_staff', source_id: data.item.pivot.id, chat: 1 }, -->
                  <!--     }" -->
                  <!--   > -->
                  <!--     <BIconChatLeftText /> -->
                  <!--   </b-button> -->
                  <!-- </template> -->
                </b-table>
              </b-overlay>
            </div>

            <div
              class="col-12 d-flex justify-content-center"
              style="margin-top: 80px"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                :no-local-sorting="true"
                align="center"
                @change="fetchCustomers"
              ></b-pagination>
            </div>

            <div
              v-if="$route.name != 'MyConsoleStaffCustomerList'"
              class="col-md-6"
            >
              <div class="float-right">
                <b-button
                  class="mr-3"
                  @click="goListPage"
                  variant="outline-danger"
                  >返回</b-button
                >
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import staffApi from "@/apis/staff";
import meCustomerApi from "@/apis/me/customer";
import organizationApi from "@/apis/organization";
import branchApi from "@/apis/branch";
import { mapState, mapGetters } from "vuex";
import CustomerStaffOperator from "@/components/CustomerStaffOperator";
import _ from "lodash";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import branchMixin from "@/mixins/Dashboard/branches";
import StaffAddCustomer from "@/pages/Dashboard/Staff/StaffAddCustomer.vue";

export default {
  components: { CustomerStaffOperator, StaffAddCustomer },
  mixins: [branchMixin],
  data() {
    return {
      staff: {
        name: null,
        employee_code: null,
      },
      staffs: [],
      showLoading: true,
      filter: {
        doesBound: true,
        selectedBoundTypes: [],
        keyword: null,
      },
      operator: {
        actionMode: "change-staff",
        changeToStaffId: null,
        changeBoundType: null,
      },
      boundTypes: [],
      selectedPivotIds: [],
      isSelectAll: false,
      customers: [],
      sortBy: null,
      sortDesc: null,
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      selectedBranch: null,
      changingBranch: false,
      selectedStaff: null,
      staffOptions: [],
      changingStaff: false,
      staffId: null,
      textMapping: [],
      myBranchesOptions: [],
      consts,
    };
  },
  computed: {
    ...mapState("auth", {
      user: (state) => state.user,
    }),
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapGetters("general", ["organization", "role", "branchOptions"]),
    ...mapGetters("route", ["routeQuery"]),
    fromMyConsole() {
      return this.$route.name == "MyConsoleStaffCustomerList";
    },
    breadcrumbItems() {
      switch (this.$route.name) {
        case "StaffCustomerList":
          return [
            {
              to: { name: "StaffList" },
              name: "帳號管理",
            },
            {
              to: { name: "StaffList" },
              name: "帳號列表",
            },
          ];
        case "BranchStaffCustomerList":
          return [
            {
              to: { name: "Branches" },
              name: "分店管理",
            },
            {
              to: { name: "BranchStaffs" },
              parmas: { branchID: this.$route.params.branchID },
              name: "店內帳號",
            },
          ];
        case "MyConsoleStaffCustomerList":
          return [
            {
              to: null,
              name: "我的主控台",
              active: true,
            },
          ];
        case "MyConsoleBranchStaffCustomerList":
          return [
            {
              to: null,
              name: "我的主控台",
              active: true,
            },
          ];
        default:
          return [];
      }
    },
    title() {
      switch (this.$route.name) {
        case "StaffCustomerList":
        case "BranchStaffCustomerList":
          return "所屬客戶";
        case "MyConsoleStaffCustomerList":
          return "我的客戶";
        case "MyConsoleBranchStaffCustomerList":
          return "部門客戶";
        default:
          return "";
      }
    },
    staffNameLabel() {
      if (this.textMapping.length) {
        return this.textMapping.find((tm) => {
          return tm.key === "staff.name";
        })["display_name"];
      }
      return "員工姓名";
    },
    staffIdLabel() {
      if (this.textMapping.length) {
        return this.textMapping.find((tm) => {
          return tm.key === "staff.id";
        })["display_name"];
      }
      return "員工編號";
    },
    customerNameLabel() {
      if (this.textMapping.length) {
        return this.textMapping.find((tm) => {
          return tm.key === "customer.name";
        })["display_name"];
      }
      return "LINE 名稱";
    },
    fields() {
      let table = [
        {
          key: "id",
          label: "勾選",
        },
        {
          key: "avatar_url",
          label: "",
        },
        {
          key: "name",
          label: this.customerNameLabel,
          sortable: true,
        },
        {
          key: "mobile_phone",
          label: "手機",
        },
        {
          key: "bound_at",
          label: "綁定日期",
          sortable: true,
        },
        {
          key: "unbound_at",
          label: "解綁日期",
          sortable: true,
        },
        {
          key: "bound_type",
          label: "綁定類型",
          sortable: true,
        },
      ];
      if (this.title == "部門客戶") {
        table.splice(1, 0, { key: "staff_name", label: this.staffNameLabel });
      }

      return table;
    },
    showStaffAddCustomer() {
      if (this.checkPermission([consts.CUSTOMER_STAFF_BIND_CUSTOMER])) {
        if (this.title == "部門客戶") {
          return this.staff.name !== null;
        }
        return true;
      }
      return false;
    },
  },
  async mounted() {
    await this.getSaleConfigsModule();
    this.fetchStaffInit();
    if (this.checkPermission([this.consts.BRANCH_TOP_VIEW])) {
      const { data } = await branchApi.getBranches(this.organization, {
        is_all: true,
      });
      this.myBranchesOptions = this.sortBranch(data.data, this.organization.id);
    } else {
      this.myBranchesOptions = this.branchOptions;
    }
    if (this.title !== "部門客戶") {
      this.fetchCustomers();
    }
    this.showLoading = false;
  },
  methods: {
    goToCustomer(customer) {
      this.$router.push({
        name: "CustomerDetailView",
        params: {
          customerID: customer.id,
        },
      });
    },
    async handleChangeSelectedBranch(selectedBranch) {
      if (this.changingBranch) return;

      this.changingBranch = true;
      this.staffId = null;
      this.selectedStaff = null;
      this.selectedBranch = selectedBranch;
      await this.fetchStaffs(this.selectedBranch);
      this.changingBranch = false;
    },
    async fetchStaffs(selectedBranch) {
      if (!selectedBranch) return;
      const { data } = await branchApi.getBranchStaffs(selectedBranch);
      this.staffs = _.sortBy(data.data, "employee_code");
      this.staffOptions = this.staffs.map((s) => {
        return {
          text: `${s.name} - ${s.employee_code}`,
          value: s.id,
        };
      });
    },
    async bindCustomer(customer) {
      this.$swal({
        title: "確定要綁定嗎?",
        html: `
          <div class="d-block">
            <div class="my-3 text-left">
              <div>專員姓名: ${this.staff.name}</div>
              <div>消費者姓名:${customer.name}</div>
            </div>
          </div>`,
        type: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "返回",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "確認綁定",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          try {
            await this.doBindCustomer(customer);
          } catch (e) {
            console.log(e);
          } finally {
            this.$refs.staffAddCustomer.refreshSearch();
          }
        }
      });
    },
    async doBindCustomer(customer) {
      try {
        await staffApi.bindCustomer(this.staff.id, {
          customer_id: customer.id,
        });
        this.fetchCustomers();
      } catch (error) {
        console.error(error);

        this.$swal(
          "綁定失敗",
          `${customer.name} 與 ${this.staff.name} 已經綁定，無法重複綁定`,
          "warning"
        );
      }
    },
    async searchCustomer() {
      this.showLoading = true;
      if (this.staffId) {
        this.staff = this.staffs.find((s) => {
          return s.id === this.staffId;
        });
      } else {
        this.staff = {
          name: null,
          employee_code: null,
        };
      }
      this.fetchCustomers();
      this.showLoading = false;
    },
    handleChangeSelectedStaff(selectedStaff) {
      if (this.changingStaff) return;
      this.changingStaff = true;
      this.staffId = selectedStaff;
      this.changingStaff = false;
    },
    async fetchBranchCustomers() {
      const { data } = await branchApi.getBranchCustomers(this.selectedBranch, {
        doesBound: this.filter.doesBound,
        selectedBoundTypes: this.filter.selectedBoundTypes.map(
          (type) => type.value
        ),
        keyword: this.filter.keyword,
      });
      return data;
    },
    hiddenString(value, length) {
      value = value || "";
      const splittedValue = [...value];
      if (splittedValue.length <= length) {
        return value;
      }
      return splittedValue.slice(0, length).join("") + "...";
    },
    async getSaleConfigsModule() {
      try {
        const { data } = await organizationApi.getModuleConfig(
          this.organization.id,
          { module_code: "sales-config" }
        );

        if (data.bound_type_filter) {
          let rolesBoundType = data.bound_type_filter.roles[this.role];
          if (!rolesBoundType) {
            rolesBoundType = data.bound_type_filter.default;
          }

          const boundTypes = data.bound_type_filter.bound_type.filter(
            (boundType) => {
              // * 代表全部都過
              if (rolesBoundType.includes("*")) {
                return true;
              } else {
                return rolesBoundType.includes(boundType.key);
              }
            }
          );

          this.boundTypes = boundTypes;

          this.filter.selectedBoundTypes = boundTypes
            .filter((boundType) => {
              return boundType.default_selected;
            })
            .map((boundType) => {
              return { value: boundType.key, text: boundType.display_name };
            });
        }

        if (data.text_mapping) {
          this.textMapping = data.text_mapping;
        }
      } catch (e) {
        console.log(e);
      }
    },
    fetchStaffInit() {
      if (this.fromMyConsole) {
        this.staff = {
          id: this.user.id,
          name: this.user.name,
          employee_code: this.user.employee_code,
        };
      } else if (this.title == "部門客戶") {
        this.selectedBranch = this.myBranchesOptions[0]?.value ?? null;
        this.fetchStaffs(this.selectedBranch);
      } else {
        this.staffId = this.$route.params.staff_id;
        this.fetchStaffFromId();
      }
    },
    async fetchStaffFromId() {
      this.showLoading = true;
      let response = await staffApi.getOneStaff(this.staffId);
      if (response.status === 200 && response.data.data) {
        this.staff = response.data.data;
      } else {
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: "取得帳號資料失敗",
        });
      }
      this.showLoading = false;
    },
    async fetchCustomers() {
      this.showLoading = true;
      try {
        let data;
        if (this.fromMyConsole) {
          data = await this.fetchCustomersFromMy();
        } else if (this.title == "部門客戶" && !this.staffId) {
          data = await this.fetchBranchCustomers();
        } else {
          data = await this.fetchCustomersFromStaffId();
        }

        this.customers = _.orderBy(data.data, "bound_at", "desc");
        this.totalRows = data.data.length;
      } catch (e) {
        console.log(e);
      }
      this.showLoading = false;
    },
    async fetchCustomersFromMy() {
      const { data } = await meCustomerApi.getCustomers({
        doesBound: this.filter.doesBound,
        selectedBoundTypes: this.filter.selectedBoundTypes.map(
          (type) => type.value
        ),
        keyword: this.filter.keyword,
      });
      return data;
    },
    async fetchCustomersFromStaffId() {
      const { data } = await staffApi.getCustomers(this.staffId, {
        doesBound: this.filter.doesBound,
        selectedBoundTypes: this.filter.selectedBoundTypes.map(
          (type) => type.value
        ),
        keyword: this.filter.keyword,
      });
      return data;
    },
    showChangeStaff() {
      if (!this.validateSelectStaff()) return;
      if (!this.operator.changeToStaffId) {
        this.$swal.fire({
          title: "請選擇轉移業務",
          type: "error",
        });
        return;
      }

      this.$swal({
        title: "確定要轉移業務嗎？",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "確定轉移",
        cancelButtonText: "返回",
        reverseButtons: true,
        cancelButtonColor: "#fff",
        confirmButtonClass: "btn btn-lg btn-primary m-1 h3",
        cancelButtonClass: "btn btn-lg btn-outline-primary m-1 h3",
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          await this.changeStaff();
        }
      });
    },
    async changeStaff() {
      try {
        const response = await this.doChangeStaff();
        this.selectedPivotIds = [];

        if (response.status === 200) {
          this.$swal.fire({
            title: "成功",
            type: "success",
            text: `轉移成功`,
          });
          this.fetchCustomers();
          this.isSelectAll = false;
        } else {
          if (response.data.message) {
            this.$swal.fire({
              title: "錯誤",
              type: "error",
              text: response.data.message,
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    async doChangeStaff() {
      let response;
      if (this.fromMyConsole) {
        response = await meCustomerApi.changeStaff({
          pivot_ids: this.selectedPivotIds,
          staff_id: this.operator.changeToStaffId,
        });
      } else if (this.title == "部門客戶") {
        // 不能用 array.forEach 因為 await 會沒有用
        for (let i = 0; i < this.selectedPivotIds.length; i++) {
          const id = this.selectedPivotIds[i];
          const customer = this.customers.find((c) => {
            return c.pivot.id == id;
          });
          await staffApi.changeStaff(customer.staff_id, {
            pivot_ids: [id],
            staff_id: this.operator.changeToStaffId,
          });
        }
        return { status: 200 };
      } else {
        response = await staffApi.changeStaff(this.staffId, {
          pivot_ids: this.selectedPivotIds,
          staff_id: this.operator.changeToStaffId,
        });
      }
      return response;
    },
    showUnbindStaff() {
      if (!this.validateSelectStaff()) return;

      this.$swal({
        title: "確定要解除綁定嗎？",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "解除綁定",
        cancelButtonText: "返回",
        reverseButtons: true,
        cancelButtonColor: "#fff",
        confirmButtonClass: "btn btn-lg btn-danger m-1 h3",
        cancelButtonClass: "btn btn-lg btn-outline-primary m-1 h3",
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          await this.unbindStaff();
        }
      });
    },
    async unbindStaff() {
      try {
        const response = await this.doUnbindStaff();
        this.selectedPivotIds = [];

        if (response.status === 200) {
          this.$swal.fire({
            title: "成功",
            type: "success",
            text: "解綁成功",
          });
          this.fetchCustomers();
          this.isSelectAll = false;
        } else {
          if (response.data.message) {
            this.$swal.fire({
              title: "錯誤",
              type: "error",
              text: response.data.message,
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    async doUnbindStaff() {
      let response;
      if (this.fromMyConsole) {
        response = await meCustomerApi.unbindCustomers({
          pivot_ids: this.selectedPivotIds,
        });
      } else if (this.title == "部門客戶") {
        // 不能用 array.forEach 因為 await 會沒有用
        for (let i = 0; i < this.selectedPivotIds.length; i++) {
          const id = this.selectedPivotIds[i];
          const customer = this.customers.find((c) => {
            return c.pivot.id == id;
          });
          await staffApi.unbindCustomers(customer.staff_id, {
            pivot_ids: [id],
          });
        }
        return { status: 200 };
      } else {
        response = await staffApi.unbindCustomers(this.staffId, {
          pivot_ids: this.selectedPivotIds,
        });
      }
      return response;
    },
    showChangeBoundType() {
      if (!this.validateSelectStaff()) return;
      if (!this.operator.changeBoundType) {
        this.$swal.fire({
          title: "請選擇變更類型",
          type: "error",
        });
        return;
      }

      this.$swal({
        title: "確定要變更類型嗎？",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "確定變更",
        cancelButtonText: "返回",
        reverseButtons: true,
        cancelButtonColor: "#fff",
        confirmButtonClass: "btn btn-lg btn-primary m-1 h3",
        cancelButtonClass: "btn btn-lg btn-outline-primary m-1 h3",
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          await this.changeBoundType();
        }
      });
    },
    async changeBoundType() {
      try {
        const response = await this.doChangeBoundType();
        this.selectedPivotIds = [];

        if (response.status === 200) {
          this.$swal.fire({
            title: "成功",
            type: "success",
            text: `變更成功`,
          });
          this.fetchCustomers();
          this.isSelectAll = false;
        } else {
          if (response.data.message) {
            this.$swal.fire({
              title: "錯誤",
              type: "error",
              text: response.data.message,
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    async doChangeBoundType() {
      let response;
      if (this.fromMyConsole) {
        response = await meCustomerApi.changeBoundType({
          pivot_ids: this.selectedPivotIds,
          bound_type: this.operator.changeBoundType,
        });
      } else if (this.title == "部門客戶") {
        // 不能用 array.forEach 因為 await 會沒有用
        for (let i = 0; i < this.selectedPivotIds.length; i++) {
          const id = this.selectedPivotIds[i];
          const customer = this.customers.find((c) => {
            return c.pivot.id == id;
          });
          await staffApi.changeBoundType(customer.staff_id, {
            pivot_ids: [id],
            bound_type: this.operator.changeBoundType,
          });
        }
        return { status: 200 };
      } else {
        response = await staffApi.changeBoundType(this.staffId, {
          pivot_ids: this.selectedPivotIds,
          bound_type: this.operator.changeBoundType,
        });
      }
      return response;
    },
    validateSelectStaff() {
      let warning = "";
      if (
        this.operator.actionMode == "change-staff" &&
        this.operator.changeToStaffId == this.staff.id
      ) {
        warning = "轉移與被轉移業務相同，無法轉移";
      }

      if (this.selectedPivotIds.length == 0) {
        warning = "請勾選客戶名單";
      }

      if (warning) {
        this.$swal.fire({
          title: warning,
          type: "error",
        });
        return false;
      }
      return true;
    },
    handleSelectAll(value) {
      if (value) {
        this.selectedPivotIds = this.customers.map(
          (customer) => customer.pivot.id
        );
      } else {
        this.selectedPivotIds = [];
      }
    },
    handleChecboxChange() {
      if (
        this.selectedPivotIds.length == this.customers.length &&
        this.customers.length != 0
      ) {
        this.isSelectAll = true;
      }
      if (
        this.selectedPivotIds.length != this.customers.length ||
        this.customers.length == 0
      ) {
        this.isSelectAll = false;
      }
    },
    goListPage() {
      if (this.$route.name === "StaffCustomerList") {
        this.$router.push({ name: "StaffList", query: this.routeQuery });
      }
      if (this.$route.name == "BranchStaffCustomerList") {
        this.$router.push({ name: "BranchStaffs" });
      }
    },
    mapBoundTypeName(boundTypeKey) {
      return this.boundTypes.find((boundType) => boundType.key == boundTypeKey)
        ?.display_name;
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
  },
};
</script>

<style>
.table-responsive {
  min-height: 300px;
}
</style>
